import { useEffect, useState } from 'react';

const useAiChatScroll = (containerRef, responseList) => {
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

  const isNearBottom = () => {
    const container = containerRef.current;
    if (!container) {
      return false;
    }
    const threshold = 30;
    const position = container.scrollHeight - container.scrollTop - container.clientHeight;
    return position <= threshold;
  };

  const handleScroll = () => {
    setAutoScrollEnabled(isNearBottom());
  };

  useEffect(() => {
    if (autoScrollEnabled && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [containerRef, responseList, autoScrollEnabled]);

  return handleScroll;
};

export default useAiChatScroll;
