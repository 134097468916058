import { Helmet } from 'react-helmet';
import SubMenu from '../components/CookiePolicy/SubMenu';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CookiePolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="cookie-policy3">
      <Helmet>
        <title>Releva AI | Privacy Policy</title>
        <meta
          name="description"
          content="Review Releva AI's Privacy Policy to understand how we protect your personal information. Learn about our data collection practices, usage, and your rights regarding your privacy and data security while using our services."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.privacyPolicyPage)}</script>
      </Helmet>
      <section className="cookie-policy-container">
        <h1 className="cookie-policy4">Privacy Policy</h1>
      </section>
      <SubMenu active="privacy-policy" />
      <section className="at-releva-we-respect-individua-wrapper">
        <div className="at-releva-we-container">
          <p className="the-administrator-of-your-pers">
            <span className="the-administrator-of-your-pers1">
              <span className="the-administrator-of-your-pers2">
                <span className="the-administrator-of">
                  The Administrator of your personal data is Releva Plc., based in EU, Sofia, Lozenets, 18 Harambi
                  Tashev str., registered under the Commercial Register of Bulgaria under the identification number
                  207561080.
                </span>
              </span>
            </span>
          </p>
          <p className="personal-data-is-processed-in">
            <span className="personal-data-is-processed-in1">
              <span className="personal-data-is-processed-in2">
                <span className="personal-data-is">
                  Personal data is processed in connection with contract performance, invoicing, payments, marketing of
                  own products or services, and concluding contracts. You have the right to withdraw your consent at any
                  time. Personal data will be processed (and stored) for the periods specified in the relevant legal
                  regulations (such as the Tax Ordinance Act and the Accounting Act) or until the expiration of the
                  contract. Your personal data will not be transmitted to any third countries. The provision of your
                  personal data is voluntary unless stated otherwise. The legal basis for processing personal data is
                  Art. 6, paragraph 1, subparagraphs a-c and f of the GDPR.
                </span>
              </span>
            </span>
          </p>
          <p className="registration">
            <span className="registration1">
              <b className="registration2">Registration</b>
            </span>
          </p>
          <p className="by-filling-out-the-contact-for">
            <span>
              <span className="by-filling-out">
                By filling out the contact form, you provide us with your name, surname, e-mail address, and telephone
                number.
              </span>
            </span>
          </p>
          <p className="you-have-the-right-to">
            <span>
              <b className="you-have-the7">You have the right to:</b>
            </span>
          </p>
          <ul className="access-your-personal-data-cor">
            <li className="access-your-personal-data-cor1">
              <span>
                <span className="access-your-personal">
                  access your personal data, correct it, delete it or restrict the scope of its processing,
                </span>
              </span>
            </li>
            <li className="export-data">
              <span>
                <span className="export-data1">export data,</span>
              </span>
            </li>
            <li className="make-a-complaint-to-the-superv">
              <span>
                <span className="make-a-complaint">make a complaint to the supervisory organ,</span>
              </span>
            </li>
            <li className="make-a-written-and-reasoned-de">
              <span>
                <span className="make-a-written">
                  make a written and reasoned demand for ceasing the data processing because of your specific situation,
                </span>
              </span>
            </li>
            <li className="raise-an-objection-to-the-proc">
              <span>
                <span className="raise-an-objection">
                  raise an objection to the processing of your data in the event that the Administrator of your data is
                  going to process them for other purposes or pass your personal data to other administrators,
                </span>
              </span>
            </li>
            <li className="receive-detailed-information-a">
              <span>
                <span className="receive-detailed-information">receive detailed information about:</span>
              </span>
            </li>
            <li className="the-presence-of-your-data-in-t">
              <span>
                <span className="the-presence-of">
                  the presence of your data in the Administrator’s data sets and about the address of the
                  Administrator’s registered office,
                </span>
              </span>
            </li>
            <li className="the-purpose-scope-and-method">
              <span>
                <span className="the-purpose-scope">
                  the purpose, scope, and method of processing data in such a data set,
                </span>
              </span>
            </li>
            <li className="the-state-from-when-your-data">
              <span>
                <span className="the-state-from">the state from when your data is processed in the data set,</span>
              </span>
            </li>
            <li className="any-potential-source-of-the-da">
              <span>
                <span className="any-potential-source">any potential source of the data,</span>
              </span>
            </li>
            <li className="the-sharing-of-your-data-espe">
              <span>
                <span className="the-sharing-of">
                  the sharing of your data; especially information about the receivers or the categories of receivers
                  with whom your data is shared.
                </span>
              </span>
            </li>
          </ul>
          <p className="personal-data-is-shared-with-e">
            <span>
              <span className="personal-data-is1">
                Personal data is shared with entities cooperating with the Administrator, entities authorized by virtue
                of law, and for the purpose of performing the contract. Data will be processed in an automated form,
                including profiling.
              </span>
            </span>
          </p>
          <p className="complains-questions-requests">
            <b className="complains-questions-requests1">Complains, Questions, Requests</b>
          </p>
          <ol className="you-may-submit-complaints-inq2">
            <li className="you-may-submit-complaints-inq3">
              <span className="you-may-submit1">
                You may submit complaints, inquiries, and requests regarding the processing of your personal data and
                the exercise of your rights.
              </span>
            </li>
            <li className="if-you-believe-that-your-right">
              <span className="if-you-believe">
                If you believe that your right to data protection or other rights granted to you under the GDPR have
                been violated, you have the right to file a complaint with the President of the Commission for Personal
                Data Protection, address: 2 Prof. Tsvetan Lazarov Blvd., Sofia 1592 (www.cpdp.bg)
              </span>
            </li>
          </ol>
          <p className="processing-time">
            <b className="processing-time1">Processing time</b>
          </p>
          <p className="personal-data-of-users-is-stor">
            <span>
              Personal data of Users is stored for the period of holding an Account in the Service for the purpose of
              providing the Account service and related functionalities and other services in accordance with the
              Regulations, as well as for marketing purposes. After deleting the Account, your personal data will be
              anonymized.
            </span>
          </p>
          <p className="in-the-matters-related-to-pers">
            <span>
              In the matters related to personal data protection, you can contact us on the address mentioned above or
              write at 
            </span>
            <a rel="noopener noreferrer" className="inforelevaai2" href="mailto:info@releva.ai" target="_blank">
              <span>
                <span className="inforelevaai3">info@releva.ai</span>
              </span>
            </a>
          </p>
          <p className="processing-time">
            <b className="processing-time1">Data Security</b>
          </p>
          <p className="in-the-matters-related-to-pers">
            <span>
              We implement comprehensive security procedures to protect the confidentiality of our logged in users data.
              This includes utilizing encryption technologies to safeguard your information during transmission and
              storage. Access their personal data is restricted to authorized personnel only, ensuring that the
              information remains secure and confidential.
            </span>
          </p>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
