import { Helmet } from 'react-helmet';
import HomePageHeroTitle from '../components/HomePage/HomePageHeroTitle';
import DescribingSectionsLeft from '../components/Solutions/DescribingSectionsLeft';
import DescribingSectionsRight from '../components/Solutions/DescribingSectionsRight';
import Testimonials from '../components/Testimonials';
import TrustedCustomersPurple from '../components/TrustedCustomersPurple';
import EssentialPartner from '../components/WhyReleva/EssentialPartner';
import IndustryAchievedMetrics from '../components/WhyReleva/IndustryAchievedMetrics';
import TranshormEcommerceGrowth from '../components/WhyReleva/TranshormEcommerceGrowth';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CaseStudies.css';
import './Overview.css';

const WhyReleva = () => {
  return (
    <div className="overview solutions whyReleva">
      <Helmet>
        <title>Releva AI | Why Releva</title>
        <meta
          name="description"
          content="Explore Releva AI's comprehensive overview. Learn how our AI-driven marketing platform can transform your business with personalized customer experiences, data-driven insights, and automated campaigns. Discover the future of marketing with Releva AI."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.whyReleva)}</script>
      </Helmet>
      <div className="solutionsHero">
        <HomePageHeroTitle
          heroTitle="Unify All Your Customer Touchpoints on One Powerful Platform"
          heroDescription="Transform your online presence into a trusted shopping destination by seamlessly integrating expert knowledge with AI capabilities. Deliver hyper personalization throughout the customer journey—whether they're browsing your website, using your app, or engaging through any other channel."
          heroYoutube={false}
        />
        <section className="symbol-library">
          <img className="image-118-icon hero-image" loading="lazy" alt="" src="/WhyReleva/whyReleva01.png" />
        </section>
      </div>

      <div className="solidPurpleBckg">
        <EssentialPartner />
      </div>

      <DescribingSectionsLeft
        className="logic-gate12 first"
        title="Seamless Personalization Across Every Channel"
        descriptions="<p>Built from the ground up for today's engagement channels—and tomorrow's innovations. Our lightning-fast journey orchestration connects customers with relevant products and experiences in milliseconds. The data flow creates a continuous feedback loop, helping you understand what resonates and how to optimize every interaction.</p>"
        image="/WhyReleva/WhyReleva02.png"
      />
      <br />
      <div className="subscriptionform"></div>
      <DescribingSectionsRight
        title="Your 24/7 Veterinary Sales Consultant"
        descriptions="<p>Our AI combines veterinary knowledge with retail expertise, understanding not just products, but pets and their parents. It automatically considers pet age, breed, weight, activity level, and health needs alongside owner preferences and shopping patterns to deliver perfect recommendations.</p>"
        image="/WhyReleva/WhyReleva03.png"
      />
      <div className="solidPurpleBckg borderedImage">
        <DescribingSectionsRight
          title="Proven Results Across Every Industry"
          descriptions={`<p>Discover how Releva's unified customer engagement platform delivers exceptional performance across diverse retail sectors. Our comprehensive presentation showcases real success stories from Fast-Moving, Medium-Turnover, and Slow-Moving Consumer Goods businesses that have transformed their customer engagement strategies with our solution.</p><br/> 
<button class="button5"><a class="button-name5" href="/case-studies-details?case-study=industry-success-stories"
" style="width:auto" target="_blank">Download Industry Success Stories</a></button>
            `}
          image="/WhyReleva/WhyReleva04.jpeg"
        />
        <IndustryAchievedMetrics />
      </div>
      <DescribingSectionsLeft
        className="logic-gate12 first"
        title="Effortless Integration and Intuitive Management"
        descriptions="<p>Connect instantly with 10+ integrations for your platform. Our open APIs enable deep customization to fit your unique business needs. The interface is designed for teams of all sizes and technical abilities—with intuitive segmentation, user-friendly message editors, and powerful experimentation tools that deliver measurable results through advanced personalization.</p>"
        image="/WhyReleva/WhyReleva05.png"
      />
      <br />
      <DescribingSectionsRight
        title="Technology Backed by a Dedicated Expert Team"
        descriptions="<p>Join a thriving ecosystem with 30+ global partners and 400+  leading companies across industries, sizes, and regions. Our success and service teams provide expert guidance, data-driven insights, and continuous support throughout your journey.</p>"
        image="/WhyReleva/WhyReleva06.png"
      />
      <Testimonials />
      <br />
      <TrustedCustomersPurple />
      <div className={`releva-homepage-child sliderHolder`}>
        <div className={`slider-container solutionsSlider`}>
          <TranshormEcommerceGrowth />
        </div>
      </div>
      <br />
    </div>
  );
};

export default WhyReleva;
