import { Helmet } from 'react-helmet';
import HomePageHeroTitle from '../components/HomePage/HomePageHeroTitle';
import EssentialPartner from '../components/JourneyOrchestration/EssentialPartner';
import IndustryAchievedMetrics from '../components/JourneyOrchestration/IndustryAchievedMetrics';
import DescribingSectionsLeft from '../components/Solutions/DescribingSectionsLeft';
import DescribingSectionsRight from '../components/Solutions/DescribingSectionsRight';
import Testimonials from '../components/Testimonials';
import TrustedCustomersPurple from '../components/TrustedCustomersPurple';
import TranshormEcommerceGrowth from '../components/WhyReleva/TranshormEcommerceGrowth';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CaseStudies.css';
import './Overview.css';

const JourneyOrchestration = () => {
  return (
    <div className="overview solutions whyReleva">
      <Helmet>
        <title>Releva AI | Journey Orhecstration</title>
        <meta
          name="description"
          content="Explore Releva AI's comprehensive overview. Learn how our AI-driven marketing platform can transform your business with personalized customer experiences, data-driven insights, and automated campaigns. Discover the future of marketing with Releva AI."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.whyReleva)}</script>
      </Helmet>
      <div className="solutionsHero">
        <HomePageHeroTitle
          heroTitle="Journey Orchestration That Delivers Results"
          heroDescription="Turn casual browsers into loyal customers with personalized, seamless experiences across every touchpoint. Releva's powerful platform makes it possible."
          heroYoutube={false}
        />
        <section className="symbol-library">
          <img className="image-118-icon hero-image" loading="lazy" alt="" src="/JourneyOrchestration/JO01.png" />
        </section>
      </div>

      <div className="solidPurpleBckg">
        <EssentialPartner />
      </div>

      <DescribingSectionsLeft
        id="one"
        className="logic-gate12 first listStyleHolder"
        title="Launch Powerful Campaigns in Minutes, Not Months"
        descriptions={`
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon5.png" /></div><div class="textHolder"><h5>Intuitive Visual Builder</h5><p>Transform complex customer journeys into simple visual workflows. Our drag-and-drop interface lets you design, test, and deploy sophisticated campaigns without writing a single line of code.</p></div><div class="clearfix"></div></div>
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon6.png" /></div><div class="textHolder"><h5>Smart Product Recommendations</h5><p>Boost repeat purchases with our intelligent recommendation engine. Automatically guide customers to their next perfect product based on real-time behavior, preferences, and purchase history.</p></div><div class="clearfix"></div></div>
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon7.png" /></div><div class="textHolder"><h5>AI-Powered Content Creation</h5><p>Accelerate your creative process with our AI Hub. Generate compelling copy, eye-catching visuals, and conduct quality checks in a fraction of the time—all within the same platform.</p></div><div class="clearfix"></div></div>
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon8.png" /></div><div class="textHolder"><h5>True No-Code Empowerment</h5><p>Put the power of personalization in the hands of your marketing team. Design, launch, and optimize campaigns independently—no developers or external agencies required.</p></div><div class="clearfix"></div></div>
          `}
        image="/JourneyOrchestration/JO02.png"
      />
      {/* <br /> */}
      <div className="subscriptionform"></div>
      <DescribingSectionsRight
        id="two"
        className="listStyleHolder second"
        title="Launch Powerful Campaigns in Minutes, Not Months"
        descriptions={`
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon9.png" /></div><div class="textHolder"><h5>Team-Powered Workflows</h5><p>Bring your entire team into the customer experience process with intuitive commenting—creating seamless collaboration whether you're working with 2 people or 200.</p></div><div class="clearfix"></div></div>
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon10.png" /></div><div class="textHolder"><h5>Effortless Optimization</h5><p>Quickly adapt and refine your existing strategies with simple iteration tools that help you focus on what works. Maximum results, minimal effort.</p></div><div class="clearfix"></div></div>
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon11.png" /></div><div class="textHolder"><h5>Scalable for Any Business</h5><p>From startups to enterprises, our platform scales to fit your specific needs and brand requirements, making sophisticated customer engagement accessible at any level.</p></div><div class="clearfix"></div></div>
          `}
        image="/JourneyOrchestration/JO03.png"
      />
      <div className="solidPurpleBckg borderedImage">
        <DescribingSectionsRight
          title="Turning Browsers into Buyers: The T-Market Success Story"
          descriptions={`<i style="font-weight: 400;">"Releva has exceeded our expectations in every way. The integration process was remarkably smooth, and we were able to implement their solution with minimal disruption to our existing workflows. What truly stands out is their lightning-fast response time and consistent success rate. From day one, we've experienced nothing but efficiency and reliability. I can't imagine using any other service now."</i><p>Mladen Vladimirov, Head of E-commerce at T-Market</p><br/><button class="button5"><a class="button-name5" href="/case-studies-details?case-study=tmarket" style="width:auto" target="_blank">Download the Case Study</a></button>`}
          image="/JourneyOrchestration/JO04.jpeg"
        />
        <IndustryAchievedMetrics />
      </div>

      <DescribingSectionsLeft
        id="three"
        className="logic-gate12 first listStyleHolder"
        title="Data-Driven Decisions. Measurable Results."
        descriptions={`
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon12.png" /></div><div class="textHolder"><h5>Experiment Without Limits</h5><p>Discover your winning strategy by testing complete customer journeys, individual touchpoints, or specific messages—all with the precision and flexibility your campaigns deserve.</p></div><div class="clearfix"></div></div>
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon13.png" /></div><div class="textHolder"><h5>One-Click Optimization</h5><p>Transform complex A/B testing into a simple toggle. Launch, measure, and refine your campaigns without technical expertise or complicated setups.</p></div><div class="clearfix"></div></div>
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon14.png" /></div><div class="textHolder"><h5>Perfect Timing, Perfect Channel</h5><p>Automatically deliver your messages when and where your customers are most receptive—leveraging real-time data to maximize engagement across all communication channels.</p></div><div class="clearfix"></div></div>
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon15.png" /></div><div class="textHolder"><h5>AI-Powered Performance</h5><p>Let our intelligent algorithms do the heavy lifting, continuously optimizing your recommendations, campaigns and automatically guiding each customer through their highest-converting journey path.</p></div><div class="clearfix"></div></div>
          `}
        image="/JourneyOrchestration/JO05.png"
      />
      {/* <br /> */}

      <DescribingSectionsRight
        id="four"
        className="listStyleHolder third"
        title="Respond When It Matters Most"
        descriptions={`
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon16.png" /></div><div class="textHolder"><h5>Behavior-Driven Engagement</h5><p>Automatically trigger personalized journeys based on real-time customer actions, cross-channel interactions, and live data updates from your entire technology ecosystem.</p></div><div class="clearfix"></div></div>
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon17.png" /></div><div class="textHolder"><h5>Complete Customer Understanding</h5><p>Build experiences on a foundation of comprehensive first-party data that captures every interaction, preference, and signal for truly individualized communication.</p></div><div class="clearfix"></div></div>
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon18.png" /></div><div class="textHolder"><h5>Uncompromising Scalability</h5><p>Deliver flawlessly personalized experiences to millions of customers with sub-second response times—no matter how complex your campaigns or how large your audience.</p></div><div class="clearfix"></div></div>
          `}
        image="/JourneyOrchestration/JO06.png"
      />
      <TrustedCustomersPurple />
      <br />
      <DescribingSectionsLeft
        className="logic-gate12 fourth listStyleHolder"
        title="Meet Your Customers Everywhere They Are"
        descriptions={`
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon19.png" /></div><div class="textHolder"><h5>One Platform, Every Channel</h5><p>Design, deliver, and manage seamless experiences across all touchpoints—email, mobile apps, SMS, WhatsApp, Viber, web, and paid ads—all from a single, intuitive interface.</p></div><div class="clearfix"></div></div>
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon20.png" /></div><div class="textHolder"><h5>Consistent Brand Experience</h5><p>Build lasting customer loyalty by maintaining a cohesive brand voice across all interactions, eliminating fragmented messaging and disconnected experiences.</p></div><div class="clearfix"></div></div>
          <div class="listStyleProp"><div class="imageHolder"><img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon21.png" /></div><div class="textHolder"><h5>Cross-Channel Intelligence</h5><p>Create truly responsive customer journeys where actions on one channel instantly trigger personalized engagement on another—delivering the right message at precisely the right moment.</p></div><div class="clearfix"></div></div>
          `}
        image="/JourneyOrchestration/JO07.png"
      />
      <Testimonials />
      <br />

      <div className={`releva-homepage-child sliderHolder`}>
        <div className={`slider-container solutionsSlider`}>
          <TranshormEcommerceGrowth />
        </div>
      </div>
      <br />
    </div>
  );
};

export default JourneyOrchestration;
