import './IndustryAchievedMetrics.css';

const TranshormEcommerceGrowth = () => {
  return (
    <section className="frame-parent141 industryMetrics TranshormEcommerceGrowth" id="releva-vision">
      <h5>Ready to transform your e-commerce growth with Releva?</h5>
      <div className="elements1">
        <div className="text-1">
          <div className="text4">
            <div className="new-market-niche">Releva’s Approach to AI</div>
            <div className="through-generative-personaliza-container">
              <p className="we-use-real-time">
                See how AI Hub can help you boost revenue through personalized customer experiences. Share your details
                below, and our expert team will show you how.
              </p>
            </div>
            <button className="button2">
              <a
                rel="noopener noreferrer"
                className="button-name2"
                target="_blank"
                href="https://relevaad.zohobookings.eu/#/187906000000034054"
              >
                Book a demo
              </a>
            </button>
          </div>
        </div>
        <div className="text-2">
          <div className="text5">
            <div className="banner1">
              <div className="actions-based-on">Benchmark Report 2024</div>
              <div className="we-use-real-time">
                See why seamless cross-channel engagement matters for today's brands and how Releva's platform gives you
                the tools to excel, connecting every customer touchpoint for better results.
              </div>
              <button className="button2">
                <a
                  rel="noopener noreferrer"
                  className="button-name2"
                  target="_blank"
                  href="/case-studies-details?case-study=releva-2024-benchmark-report"
                >
                  Download Report
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="text-3">
          <div className="text6">
            <div className="exceptional-experience-more">Contact Sales</div>
            <div className="we-use-real-time">
              See how our platform can instantly boost your customer engagement, drive growth, and build lasting
              customer loyalty.
            </div>
            <button className="button2">
              <a rel="noopener noreferrer" className="button-name2" target="_blank" href="mailto:maria@releva.ai">
                Contact Sales
              </a>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TranshormEcommerceGrowth;
