import './IndustryAchievedMetrics.css';

const EssentialPartner = () => {
  return (
    <section className="frame-parent141 industryMetrics essentialPartner jopage" id="releva-vision">
      <h5 className="text-center">The All-in-One Solution for Customer Journey Excellence</h5>
      <div className="elements1">
        <div className="text-1" style={{ width: '22%' }}>
          <div className="text4">
            <a href="/journey-orchestration#one">
              <img className="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon1.png" />
              <h5>Create Without Complexity</h5>
              <div className="we-use-real-time">
                Design sophisticated customer journeys in minutes, not months. Our intuitive drag-and-drop interface and
                no-code tools eliminate technical barriers, so your team can focus on what matters: creating experiences
                that convert.
              </div>
            </a>
          </div>
        </div>
        <div className="text-2" style={{ width: '22%' }}>
          <div className="text5">
            <div className="banner1">
              <a href="/journey-orchestration#two">
                <img className="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon2.png" />
                <h5>Harness the Power of AI</h5>
                <div className="we-use-real-time">
                  Take the guesswork out of optimization. Our AI-powered testing tools automatically identify winning
                  combinations of content, timing, channels, and offers to maximize engagement and conversion rates.
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="text-3" style={{ width: '22%' }}>
          <div className="text6">
            <a href="/journey-orchestration#three">
              <img className="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon3.png" />
              <h5>Respond to Customers in Real Time</h5>
              <div className="we-use-real-time">
                Meet your customers exactly where they are. Deliver perfectly timed, highly relevant experiences based
                on live behavior signals and cross-channel activity—all automatically scaled to fit your business needs.
              </div>
            </a>
          </div>
        </div>
        <div className="text-3" style={{ width: '22%' }}>
          <div className="text6">
            <a href="/journey-orchestration#four">
              <img className="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon4.png" />
              <h5>Unified Messaging, Unified Results</h5>
              <div className="we-use-real-time">
                Craft seamless brand experiences that follow your customers everywhere they go—all managed from one
                powerful dashboard.
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EssentialPartner;
