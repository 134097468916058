import './IndustryAchievedMetrics.css';

const IndustryAchievedMetrics = () => {
  return (
    <section className="frame-parent141 industryMetrics" id="releva-vision">
      <h5 className="industryMetricsHeading">See how companies in your industry achieved impressive metrics:</h5>
      <div className="elements1">
        <div className="text-1">
          <div className="text4">
            <div className="new-market-niche">58.7%</div>
            <div className="through-generative-personaliza-container">
              <p className="we-use-real-time">Open rate</p>
              <p className="blank-line2">&nbsp;</p>
            </div>
          </div>
        </div>
        <div className="text-2">
          <div className="text5">
            <div className="banner1">
              <div className="actions-based-on">53.6%</div>
              <div className="we-use-real-time">Click rate</div>
            </div>
          </div>
        </div>
        <div className="text-3">
          <div className="text6">
            <div className="exceptional-experience-more">64.6%</div>
            <div className="we-use-real-time">Conversion rate</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndustryAchievedMetrics;
