import React from 'react';
import { Link } from 'react-router-dom';
import caseStudiesListing from '../../constants/caseStudiesListing';
import './CaseStudiesList.css';

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const CaseStudiesList = ({ category }) => {
  const filteredCaseStudies = caseStudiesListing.filter((caseStudy) => !category || caseStudy.category === category);

  const shuffledCaseStudies = filteredCaseStudies;

  return (
    <div className="caseStudiesContainer">
      {shuffledCaseStudies
        .filter((caseStudy) => caseStudy.category) // Only keep case studies that have a category
        .map((caseStudy, index) => (
          <Link to={`/case-studies-details?case-study=${caseStudy.url}`} key={index}>
            <div className="frame-parent174">
              <img className="frame-child108" loading="lazy" src={caseStudy.image} alt={caseStudy.title} />
              <div className="ellipse-parent7">
                <div className={`frame-child109 ${caseStudy.category}`} />
                <div className={`slow-moving-consumer-goods9 ${caseStudy.category}`}>{caseStudy.category}</div>
              </div>
              <div className="momax-case-study1">{caseStudy.title}</div>
              <div className="conversion-rate-from5">{caseStudy.shortDescription}</div>
            </div>
          </Link>
        ))}
    </div>
  );
};

export default CaseStudiesList;
