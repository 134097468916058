const webPersonalizationFeatures = [
  {
    feature: 'Pop-ups and banners',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Anonymous user personalization',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature:
      'Omnichannel Recommendations : Recently Viewed; Featured Products; Best Sellers; Trending Products; Frequently Bought Together; New arrivals;',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'One-off campaigns for email & web push',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Recommended for you',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Continuous workflows',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },

  {
    feature: 'Personalized landing pages',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Personalized search',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Post Purchase: Up-sell, cross-sell and personalized delivery tracking page',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Boosts and filters to fine-tune web personalizations according to sales strategy',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
];

export default webPersonalizationFeatures;
