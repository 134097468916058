import './IndustryAchievedMetrics.css';

const EssentialPartner = () => {
  return (
    <section className="frame-parent141 industryMetrics essentialPartner" id="releva-vision">
      <h5>What Makes Releva Your Essential Partner?</h5>
      <div className="elements1">
        <div className="text-1">
          <div className="text4">
            <img className="icon-011" loading="lazy" alt="" src="/WhyReleva/icon1.png" />
            <div className="we-use-real-time">Seamless Personalization Across Every Channel</div>

            <a
              rel="noopener noreferrer"
              className="button-name2"
              target="_blank"
              href="/product-page?utm_source=releva-website&utm_medium=website&utm_campaign=website-navigation&utm_content=pageView-Header"
            >
              How Releva Works ->
            </a>
          </div>
        </div>
        <div className="text-2">
          <div className="text5">
            <div className="banner1">
              <img className="icon-011" loading="lazy" alt="" src="/WhyReleva/icon2.png" />
              <div className="we-use-real-time">Enterprise-Level Scale That Grows With You</div>
              <a
                rel="noopener noreferrer"
                className="button-name2"
                target="_blank"
                href="/case-studies?utm_source=releva-website&utm_medium=website&utm_campaign=website-navigation&utm_content=pageView-Header"
              >
                How Releva Scales ->
              </a>
            </div>
          </div>
        </div>
        <div className="text-3">
          <div className="text6">
            <img className="icon-011" loading="lazy" alt="" src="/WhyReleva/icon3.png" />
            <div className="we-use-real-time">Effortless Integration and Intuitive Management</div>
            <a
              rel="noopener noreferrer"
              className="button-name2"
              target="_blank"
              href="/partners?utm_source=releva-website&utm_medium=website&utm_campaign=website-navigation&utm_content=pageView-Header"
            >
              Explore Parners ->
            </a>
          </div>
        </div>
        <div className="text-3">
          <div className="text6">
            <img className="icon-011" loading="lazy" alt="" src="/WhyReleva/icon4.png" />
            <div className="we-use-real-time">Technology Backed by a Dedicated Expert Team</div>
            <a
              rel="noopener noreferrer"
              className="button-name2"
              target="_blank"
              href="/overview?utm_source=releva-website&utm_medium=website&utm_campaign=website-navigation&utm_content=pageView-Header"
            >
              How Releva Helps ->
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EssentialPartner;
