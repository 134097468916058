import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import FrameComponent10 from '../components/CaseStudies/FrameComponent10';
import HomePageHeroTitle from '../components/HomePage/HomePageHeroTitle';
import HubSpotForm from '../components/HubSpotForm';
import TrustedCustomers from '../components/TrustedCustomers';
import caseStudiesListing from '../constants/caseStudiesListing';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CaseStudies.css';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CaseStudies = () => {
  const query = useQuery();
  const caseStudyUrl = query.get('case-study');
  const getCaseStudyByUrl = (url) => {
    return caseStudiesListing.find((caseStudy) => caseStudy.url === url);
  };
  const caseStudy = getCaseStudyByUrl(caseStudyUrl);

  if (!caseStudy) {
    return (
      <div className="holder notFoundPage">
        <img src="/notFoundImage.png" alt="" />
        <div className="contentNotFound">
          <h1>404</h1>
          <p>even AI can't help you find what you are looking for...</p>
          <a href="/" className="button-name5">
            Go back home
          </a>
        </div>
      </div>
    );
  }
  if (caseStudy.locked) {
    return (
      <div className="case-studies locked">
        <HomePageHeroTitle heroTitle={caseStudy.heroTitle} heroDescription={caseStudy.heroDescription} />
        <section className="section-01-wrapper">
          <div className={`section-01 ivory`}>
            <div className="section-01-inner">
              <div className="hi-john-message-parent">
                <div className="streamline-abandoned-cart-reco-parent">
                  <h1 className="streamline-abandoned-cart">
                    {caseStudy.url === 'releva-2024-benchmark-report'
                      ? 'Get your industry-specific benchmark metrics guide'
                      : caseStudy.url === 'industry-success-stories'
                      ? 'Get your industry success stories guide'
                      : 'Get my FREE eBook'}
                  </h1>
                  <div className="automate-retargeting-of">
                    <HubSpotForm idForm={caseStudy.idForm} />
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-parent33">
              <img
                loading="lazy"
                src={
                  caseStudy.url === 'releva-2024-benchmark-report'
                    ? caseStudy.image
                    : caseStudy.url === 'industry-success-stories'
                    ? caseStudy.image
                    : '/HomePage/IgniteEcommerceGrowth.webp'
                }
                alt="Results"
                title="Results"
                className="HomePageHeroImage"
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
  return (
    <div className="case-studies">
      <Helmet>
        <title>Releva AI | Case Study</title>
        <meta
          name="description"
          content="Explore Releva AI's case studies to see how our AI-driven solutions have transformed businesses. Discover real-world examples of enhanced customer engagement, increased conversions, and optimized marketing strategies. Learn from our success stories."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.caseStudiesPage)}</script>
      </Helmet>
      <HomePageHeroTitle heroTitle={caseStudy.heroTitle} heroDescription={caseStudy.heroDescription} />
      {caseStudy.caseStudyData.map((caseStudy) => (
        <FrameComponent10
          key={caseStudy.number}
          number={caseStudy.number}
          color={caseStudy.color}
          title={caseStudy.title}
          description={caseStudy.description}
          image={caseStudy.image}
        />
      ))}

      {caseStudy.achievedResults.length > 0 && (
        <div className="social-proof-section-child">
          <div className="section-01 ivory frame-parent4">
            <h1 className="resultsHeading">Achieved results</h1>
            {caseStudy.achievedResults.map((result) => (
              <div className="frame-parent5" key={result.key}>
                <div className="parent">
                  <div className="div">{result.value}</div>
                  <div className="wrapper">
                    <h1 className="h11">%</h1>
                  </div>
                </div>
                <div className="increase-in-conversion-rates-wrapper">
                  <div className="increase-in-conversion">{result.key}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {caseStudy.keyMetrics.length > 0 && (
        <section className="section-01-wrapper">
          <div className={`section-01 ivory-mid`}>
            <div className="section-01-inner">
              <div className="hi-john-message-parent">
                <div className="streamline-abandoned-cart-reco-parent">
                  <h1 className="streamline-abandoned-cart">Key Metrics</h1>
                  {caseStudy.keyMetrics.map((metric) => (
                    <div className="automate-retargeting-of" key={metric.key}>
                      {metric.key}: {metric.value}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="frame-parent33">
              <img
                loading="lazy"
                src={`/CaseStudies/${caseStudy.keyMetricsImage}`}
                alt="Results"
                title="Results"
                className="HomePageHeroImage"
              />
            </div>
          </div>
        </section>
      )}
      <TrustedCustomers />
    </div>
  );
};

export default CaseStudies;
