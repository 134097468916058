import { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import ChatBotInput from './ChatBotPromptImput';
import PromptResponseList from './PromptResponseList';
import useAiChatResponse from './useAiChatResponse';
import useAiChatScroll from './useAiChatScroll';

const ChatBot = ({ responseList, prompt, handleChange, createContent, setIsOpen }) => {
  const { status, handlePromptChange, getPrompt } = useAiChatResponse(responseList, prompt, handleChange);

  const messagesListRef = useRef(null);
  const aiChatScroll = useAiChatScroll(messagesListRef, responseList);

  const [expand, setExpand] = useState(false);
  const disabled = status === 'loading' || status === 'prompting' || status === 'inputTooLong';

  return (
    <div>
      <div className={`chat-bot-container ${expand ? 'chat-bot-container-fullscreen' : ''}`}>
        <div className="chat-bot-header">
          <div className="chat-bot-align">
            <div className="chat-bot-logo">
              <img src="/ChatBot/releva-avatar.png" alt="" />
            </div>
            <div className="chat-bot-info">
              <h5 className="chat-bot-title ">Lucy</h5>
              <div className="chat-bot-align">
                <div className="chat-bot-status"></div>
                <h6 className="chat-bot-status-title">Online</h6>
              </div>
            </div>
          </div>
          <div className="chat-bot-settings">
            <Button className="chat-bot-navigation" variant="primary" onClick={() => createContent()}>
              <img src="/ChatBot/plus.svg" alt="" />
            </Button>

            <Button className="chat-bot-navigation" variant="primary" onClick={() => setExpand(!expand)}>
              {expand ? <img src="/ChatBot/shrink.svg" alt="" /> : <img src="/ChatBot/expand.svg" alt="" />}
            </Button>

            <Button className="chat-bot-navigation" variant="primary" onClick={() => setIsOpen(false)}>
              <img src="/ChatBot/minus.svg" alt="" />
            </Button>
          </div>
        </div>

        <div ref={messagesListRef} className="chat-bot-messages-container" onScroll={aiChatScroll}>
          <div id="response-list">
            <PromptResponseList
              responseList={responseList}
              isLoading={status === 'loading'}
              key="response-list"
              type="bot"
            />
          </div>
        </div>

        <div className="chat-bot-footer">
          <div id="input-container" className={disabled ? 'disabled' : ''}>
            <ChatBotInput
              prompt={prompt}
              key="prompt-input"
              updatePrompt={handlePromptChange}
              onSubmit={() => !status !== 'inputTooLong' && getPrompt()}
              disabled={disabled}
            />

            <button
              id="submit-button"
              className={`submit-button ${status === 'loading' || status === 'prompting' ? 'loading' : ''} ${
                disabled ? 'disabled' : ''
              }`}
              onClick={() => status !== 'inputTooLong' && getPrompt()}
              disabled={disabled}
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBot;
