import { useState } from 'react';
import ChatBot from './ChatBot';
import './ChatBot.css';

const defaultResponse = [
  {
    id: 'id-0000000000000-0.000000000000',
    response: "Hi, I'm Lucy! I'm here to assist you with any questions about the Releva.ai platform.",
    selfFlag: false,
  },
];

const defaultPrompt = '';

const ChatBotToggle = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [responseList, setResponseList] = useState(defaultResponse);
  const [prompt, setPrompt] = useState(defaultPrompt);

  const handleChange = (event) => {
    const { name, value } = event;

    if (name === 'prompt') {
      setPrompt(value);
    }

    if (name === 'new') {
      setResponseList((prevResponses) => [
        ...prevResponses,
        {
          id: value.uid,
          response: value.response,
          selfFlag: value.selfFlag,
        },
      ]);
    }

    if (name === 'response') {
      setResponseList((prevResponses) => {
        const updatedList = [...prevResponses];
        const index = prevResponses.findIndex((response) => response.id === value.uid);
        if (index > -1) {
          updatedList[index] = {
            ...updatedList[index],
            ...value.updatedObject,
          };
        }
        return updatedList;
      });
    }

    if (name === 'chunk') {
      setResponseList((prevResponses) => {
        const updatedList = [...prevResponses];
        const index = prevResponses.length - 1;
        if (index > -1) {
          updatedList[index] = {
            ...updatedList[index],
            response: (updatedList[index].response || '') + value.chunk,
          };
        }
        return updatedList;
      });
    }
  };

  const createContent = () => {
    setResponseList(defaultResponse);
    setPrompt(defaultPrompt);
  };

  return (
    <>
      {isOpen && (
        <ChatBot
          responseList={responseList}
          prompt={prompt}
          handleChange={handleChange}
          createContent={createContent}
          setIsOpen={setIsOpen}
        />
      )}

      <div className="chat-bot-bubble" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <div className="chat-bot-bubble-off">
            <img src="/ChatBot/close.svg" alt="" />
          </div>
        ) : (
          <div className="chat-bot-bubble-on">
            <img src="/ChatBot/releva-avatar.png" alt="" />
          </div>
        )}
      </div>
    </>
  );
};

export default ChatBotToggle;
