import './IndustryAchievedMetrics.css';

const IndustryAchievedMetrics = () => {
  return (
    <section className="frame-parent141 industryMetrics jo" id="releva-vision">
      {/* <h5 className="industryMetricsHeading">See how companies in your industry achieved impressive metrics:</h5> */}
      <div className="elements1">
        <div className="text-1">
          <div className="text4">
            <img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon22.png" />
            <div className="new-market-niche">Problem</div>
            <div className="through-generative-personaliza-container">
              <p className="we-use-real-time">
                T-Market, Bulgaria's trusted supermarket chain with 120 stores serving 100,000+ daily customers,
                struggled to convert website visitors into paying customers despite strong traffic and competitive
                prices.
              </p>
              <p className="blank-line2">&nbsp;</p>
            </div>
          </div>
        </div>
        <div className="text-2">
          <div className="text5">
            <div className="banner1">
              <img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon23.png" />
              <div className="actions-based-on">Strategy</div>
              <div className="we-use-real-time">
                Releva implemented a comprehensive AI-powered solution featuring personalized email frameworks,
                intelligent cart recovery, real-time personalization engines, and advanced customer journey
                workflows—all managed through a single, intuitive interface.
              </div>
            </div>
          </div>
        </div>
        <div className="text-3">
          <div className="text6">
            <img class="icon-011" loading="lazy" alt="" src="/JourneyOrchestration/icon24.png" />
            <div className="exceptional-experience-more">Results</div>
            <div className="we-use-real-time">
              29x higher email clicks, 7x greater conversions, 51% of purchases from our recommendations. Releva didn't
              just improve metrics—we transformed T-Market's digital performance, generating 22% of their total revenue
              through our platform.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndustryAchievedMetrics;
