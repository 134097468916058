import { Card, Col, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

import './PromptResponseList.css';

const PromptResponseList = ({ responseList, isLoading, type }) => {
  return (
    <div className="prompt-response-list">
      {type === 'chat'
        ? responseList.map((responseData) => (
            <Card
              className="mb-1 response-container"
              style={{ border: 'none', boxShadow: 'none' }}
              key={responseData.id}
            >
              <Card.Body>
                <Row>
                  <Col md={1}>
                    <img
                      className="avatar-image"
                      src={responseData.selfFlag ? '/ChatBot/me.png' : '/ChatBot/releva-avatar.png'}
                      alt="avatar"
                    />
                  </Col>
                  <Col className={responseData.error ? 'error-response ' : 'response-data-container'}>
                    {responseData.response && (
                      <ReactMarkdown
                        children={responseData.response ?? ''}
                        components={{
                          code({ className, children }) {
                            return <code className={className}>{children}</code>;
                          },
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))
        : responseList.map((responseData) => (
            <div
              className={responseData.selfFlag ? 'chat-bot-user-container' : 'chat-bot-system-container'}
              key={responseData.id}
            >
              <div className={responseData.selfFlag ? 'chat-bot-user-response' : 'chat-bot-system-response'}>
                <div className={responseData.error ? 'error-response ' : 'response-data-container'}>
                  {responseData.image && <img src={responseData.image} className="ai-image" alt="generated ai" />}
                  {responseData.response && (
                    <ReactMarkdown
                      children={responseData.response ?? ''}
                      components={{
                        code({ className, children }) {
                          return <code className={className}>{children}</code>;
                        },
                      }}
                    />
                  )}
                </div>
                <div className={responseData.selfFlag ? 'chat-bot-user-response-img' : 'chat-bot-system-response-img'}>
                  <img
                    className="avatar-image"
                    src={responseData.selfFlag ? '/ChatBot/me.png' : '/ChatBot/releva-avatar.png'}
                    alt="avatar"
                  />
                </div>
              </div>
            </div>
          ))}
      {isLoading && (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
};

export default PromptResponseList;
