import { useEffect, useRef } from 'react';
import './PromptInput.css';

const ChatBotInput = ({ prompt, onSubmit, updatePrompt }) => {
  const textAreaRef = useRef(null);

  useEffect(() => {
    handleHeightChange();
  }, [prompt]);

  const handleEnterClick = (e) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      onSubmit();
      handleHeightChange();
    }
  };

  const handleHeightChange = () => {
    textAreaRef.current.style.height = 'inherit';

    const scrollHeight = textAreaRef.current.scrollHeight;
    const maxHeight = 198;

    let heightOfTextArea = scrollHeight;
    if (scrollHeight > maxHeight) {
      heightOfTextArea = maxHeight;
    }

    textAreaRef.current.style.height = `${heightOfTextArea}px`;
  };

  const handleChange = (e) => {
    updatePrompt(e.target.value);
  };

  return (
    <textarea
      ref={textAreaRef}
      id="prompt-input"
      className="prompt-input"
      value={prompt}
      rows="1"
      onKeyDown={handleEnterClick}
      onChange={handleChange}
    />
  );
};

export default ChatBotInput;
