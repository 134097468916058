import pako from 'pako';
import { useCallback, useRef, useState } from 'react';

const useAiChatResponse = (responseList, prompt, handleChange) => {
  const MAX_TOKENS = 29000;
  const CHARS_COUNT_PER_TOKEN = 4;

  const [status, setStatus] = useState(null);
  const [promptToRetry, setPromptToRetry] = useState(null);
  const [uniqueIdToRetry, setUniqueIdToRetry] = useState();

  const controllerRef = useRef(null);

  const handlePromptChange = useCallback(
    (newPrompt) => {
      handleChange({ name: 'prompt', value: newPrompt });

      const charCount = newPrompt.length;
      const tokenCount = charCount / CHARS_COUNT_PER_TOKEN;

      if (tokenCount > MAX_TOKENS) {
        setStatus('inputTooLong');
      }
    },
    [MAX_TOKENS, handleChange]
  );

  const generateUniqueId = () => {
    const timestamp = Date.now();
    const randomNumber = Math.random();
    const hexadecimalString = randomNumber.toString(16);

    return `id-${timestamp}-${hexadecimalString}`;
  };

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const addResponse = async (selfFlag, response) => {
    const uid = generateUniqueId();
    handleChange({ name: 'new', value: { uid, selfFlag, response } });
    return uid;
  };

  const updateResponse = (uid, updatedObject) => {
    handleChange({ name: 'response', value: { uid, updatedObject } });
  };

  const updateResponseByChunk = (chunk) => {
    handleChange({ name: 'chunk', value: { chunk } });
  };

  const retryPrompt = async () => {
    await getPrompt(promptToRetry, uniqueIdToRetry);
  };

  let routeEndpoint = `https://releva.ai/api/admin/chatBot/prompt`;

  const createTextRequestBody = (_prompt) => {
    const messagesWithLimitedLength = [
      ...responseList
        .filter((r) => r.response)
        .map((response) => {
          return {
            content: response.response,
            role: response.selfFlag ? 'user' : 'assistant',
          };
        }),
      { role: 'user', content: _prompt },
    ];

    if (messagesWithLimitedLength.length) {
      let countOfTokens = messagesWithLimitedLength.map((r) => r.content).join('').length / CHARS_COUNT_PER_TOKEN;

      while (countOfTokens > MAX_TOKENS) {
        const countOfTokensInMessage = messagesWithLimitedLength[0].content.length / CHARS_COUNT_PER_TOKEN;
        messagesWithLimitedLength.shift();
        countOfTokens -= countOfTokensInMessage;
      }
    }

    return {
      messages: messagesWithLimitedLength,
    };
  };

  const uint8ArrayToBase64 = (uint8Array) => {
    return btoa(String.fromCharCode.apply(null, uint8Array));
  };

  const createCompressedRequestBody = (data) => {
    const compressedMessages = data.messages.map((message) => {
      const contentString = typeof message.content === 'string' ? message.content : JSON.stringify(message.content);
      const compressedContent = pako.gzip(contentString);
      const base64Content = uint8ArrayToBase64(compressedContent);

      return {
        ...message,
        content: base64Content,
      };
    });

    return {
      messages: compressedMessages,
    };
  };

  const getPrompt = async (_promptToRetry, _uniqueIdToRetry) => {
    // Get the prompt input
    let _prompt = _promptToRetry ?? prompt;

    // If a response is already being generated or the prompt is empty, return
    if (status === 'loading' || status === 'prompting' || !_prompt) {
      return;
    }

    setStatus('loading');
    handlePromptChange('');

    let uniqueId;
    if (_uniqueIdToRetry) {
      uniqueId = _uniqueIdToRetry;
    } else {
      // Add the self prompt to the response list

      await addResponse(true, _prompt);
      uniqueId = await addResponse(false);
      await delay(50);
    }

    try {
      let requestBody;

      requestBody = createTextRequestBody(_prompt);
      requestBody = createCompressedRequestBody(requestBody);

      controllerRef.current = new AbortController();
      const signal = controllerRef.current.signal;

      const response = await fetch(routeEndpoint, {
        method: 'POST',
        headers: {
          'content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
        signal,
      });

      setStatus('prompting');

      if (!response.ok) {
        setStatus('failed');
        const staticError = `Error: ${response.statusText}`;

        if (response.status > 400) {
          updateResponse(uniqueId, {
            response: 'We are experiencing heavy load please try again later.',
            //error: true,
          });

          return;
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        let result;
        let errorString = '';

        while (!result?.done) {
          result = await reader.read();
          let chunk = decoder.decode(result.value);
          errorString += chunk;
        }

        let finalError;
        try {
          const errorMessage = JSON.parse(errorString)?.message;
          finalError = errorMessage || staticError;
        } catch (err) {
          finalError = staticError;
        }

        updateResponse(uniqueId, {
          response: 'We are experiencing heavy load please try again later.',
        });
        if (finalError) {
          console.log(finalError);
        }

        return;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let result;
      while (!result?.done) {
        result = await reader.read();
        let chunk = decoder.decode(result.value);
        if (chunk) {
          updateResponseByChunk(chunk);
        }
      }

      setStatus('completed');
    } catch (err) {
      // aborting the stream emits AbortError; not to be displayed in the UI
      if (err.name !== 'AbortError' && err !== 'User Aborted Streaming') {
        setPromptToRetry(_prompt);
        setUniqueIdToRetry(uniqueId);
        setStatus('failed');
      }
    } finally {
      if (status === 'completed') {
        setStatus(null);
      }
    }
  };

  const abortPrompt = () => {
    controllerRef.current.abort('User Aborted Streaming');
    setStatus('aborted');
  };

  return {
    status,
    handlePromptChange,
    getPrompt,
    retryPrompt,
    abortPrompt,
  };
};

export default useAiChatResponse;
