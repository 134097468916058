import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ChatBotToggle from './components/ChatBot/ChatBotToggle';
import Footer from './components/Footer';
import Header from './components/Header';
import './global.css';

// Function to clean the URL by removing the "#/"
function cleanHashUrl() {
  const { href, origin, pathname, search } = window.location;

  // Check if the URL contains "#/"
  if (href.includes('#/')) {
    // Extract the part after "#/"
    const cleanPath = href.split('#/')[1] || '';

    // Update the URL without reloading the page
    const newUrl = `${origin}/${cleanPath}${search}`;
    window.history.replaceState(null, '', newUrl);
  }
}

cleanHashUrl(); // Clean the URL before rendering the app

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ChatBotToggle />
    <Header />
    <App />
    <Footer />
  </BrowserRouter>
);
