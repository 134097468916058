import './Achievements.css';

const Achievements = () => {
  return (
    <section className="how-it-works1 achievements">
      <div className="c-t-a-background2">
        <h1 className="experience-the-releva">Achievements & Recognition </h1>
        <div class="edge-connector">
          <div class="from-diverse-industries">Releva's global impact on eCommerce from around the world.</div>
        </div>
        <div className="frame-parent30">
          <img loading="lazy" alt="" src="/AIHub/Achievements1.webp" />
        </div>
        <div className="frame-parent30">
          <img src="/HomePage/badges.svg" />
        </div>
      </div>
    </section>
  );
};

export default Achievements;
